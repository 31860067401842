@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "black";
  src: local("Gopher"), url("./fonts/Gopher/Gopher-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "bold";
  src: local("Gopher"),
    url("./fonts/Gopher/Gopher-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "regular";
  src: local("Gopher"),
    url("./fonts/Gopher/Gopher-Light.ttf") format("truetype");
}

.black {
  font-family: "black";
}
.bold {
  font-family: "bold";
}
body {
  font-family: "regular";
}
